/**
 * During Server Side Rendering:
 *   getURLSearchParam() during SSR returns new URLSearchParams()
 *
 * In Browser, given this URL is present - "//www.example.com?utm_source=ad&utm_content=banner":
 *   getURLSearchParam() returns new URLSearchParams(window.location.search)
 *   getURLSearchParam(['utm_source', 'utm_content', 'returning_customer']) returns ['ad', 'banner', undefined]
 *
 * Errors:
 *   getURLSearchParam('utm_content') throws! Argument given must be an array of strings!
 */

/**
 * @param {string[]} args - An array of strings to be used as keys to search for in the URLSearchParams
 * @returns {URLSearchParams | string[] | undefined} - If invoked without arguments, returns new URLSearchParams(window.location.search)
 */
export const getURLSearchParam = (args: string[]) => {
  if (typeof window === "undefined") {
    return new URLSearchParams()
  }

  const windowLocationSearchParams = window?.location.search ?? ""

  if (!args) {
    return new URLSearchParams(windowLocationSearchParams)
  }

  if (args && Array.isArray(args) && args.length >= 1) {
    return args.map(arg => {
      if (typeof arg !== "string") {
        return undefined
      }
      const urlSearchParams = new URLSearchParams(windowLocationSearchParams)
      const paramFound = urlSearchParams.get(arg)
      return paramFound !== null ? paramFound : undefined
    })
  }

  throw new Error(
    "getURLSearchParam: function must be given an array of strings or be invoked without any arguments."
  )
}
