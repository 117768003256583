import React from "react"
import { useAffiliateTracking } from "@/hooks/use-affiliate-tracking"

interface RootElementProps {
  element: React.ReactNode
}

const RootElementComponent = ({ element }: RootElementProps) => {
  useAffiliateTracking()

  return <>{element}</>
}

const RootElementWrapper = ({ element }: RootElementProps) => (
  <RootElementComponent element={element} />
)

export { RootElementWrapper }
