import Cookie from "js-cookie"
import { envIsBrowser } from "./determine-env"

export const AFFILIATE_TRACKING_COOKIE_KEY = `${process.env.GATSBY_SITE}-afid`

const getAffiliateId = (
  affiliateTrackingParam = AFFILIATE_TRACKING_COOKIE_KEY
) => envIsBrowser() && Cookie.get(affiliateTrackingParam)

// Not currently use, but here if you need it!
const expireAffiliateIdCookie = (
  affiliateTrackingParam = AFFILIATE_TRACKING_COOKIE_KEY
) => envIsBrowser() && Cookie.remove(affiliateTrackingParam)

const Affiliate = {
  getAffiliateId,
  expireAffiliateIdCookie,
  AFFILIATE_TRACKING_COOKIE_KEY,
}

export { Affiliate }
