import getCreditCardType from "credit-card-type"

const formatCreditCardType = (ccNumber: string) => {
  let ccType
  try {
    ccType = getCreditCardType(ccNumber)[0].niceType.toLowerCase()
  } catch (e) {
    ccType = "NONE"
  }

  ccType = ccType === "mastercard" ? "master" : ccType
  ccType = ccType === "american express" ? "amex" : ccType // has a space
  ccType = ccType === "american-express" ? "amex" : ccType // has a hyphen

  return ccType
}

export { formatCreditCardType }
