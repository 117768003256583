import { envIsBrowser } from "./determine-env"

const urlWithoutQueryString = () =>
  envIsBrowser()
    ? [
        window.location.protocol,
        "//",
        window.location.host,
        window.location.pathname,
      ].join("")
    : ""

/**
 * By default, this replaces the current state.
 * If you want to add to history, include force: true as an option
 */
const appendParamsToCurrentUrl = (opts: {
  params: Record<string, string>
  push?: boolean
}) => {
  if (!opts?.params || Object.entries(opts.params).length === 0) {
    return
  }

  const { params, push } = opts

  const paramsToSet = {
    ...getAllQueryStringParameters(),
    ...params,
  }

  const queryString =
    Object.keys.length < 1
      ? ""
      : `?${Object.keys(paramsToSet)
          .map(key => `${key}=${encodeURIComponent(paramsToSet[key])}`)
          .join("&")}`

  if (push) {
    window.history.pushState(
      paramsToSet,
      document.title,
      urlWithoutQueryString() + queryString
    )
  } else {
    window.history.replaceState(
      paramsToSet,
      document.title,
      urlWithoutQueryString() + queryString
    )
  }
}

const getAllQueryStringParameters = () => {
  const windowLocationSearchParams = window?.location.search
  const urlParams = new URLSearchParams(windowLocationSearchParams)
  return Array.from(urlParams.entries()).reduce((acc: any, [k, v]) => {
    acc[k] = v
    return acc
  }, {})
}

export { appendParamsToCurrentUrl }
