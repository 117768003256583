import React from "react"
import { FacebookPixelComponent } from "./src/lib/tracking/components/FacebookPixelComponent"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {process.env.GATSBY_SITE === "cip" && <FacebookPixelComponent />}
    </>
  )
}
