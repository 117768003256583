import { useEffect } from "react"
import Cookie from "js-cookie"

import {
  envIsBrowser,
  getURLSearchParam,
  appendParamsToCurrentUrl,
  Affiliate,
} from "../helpers"

const trackAffiliate = (opts = {}) => {
  const {
    affiliateTrackingParam = Affiliate.AFFILIATE_TRACKING_COOKIE_KEY,
    campaign,
  } = opts
  if (envIsBrowser()) {
    const [val] = getURLSearchParam(["ref"])
    if (val) {
      Cookie.set(affiliateTrackingParam, val, { expires: 365, path: "/" })

      /**
       * If query params are passed in via opts, merge them with
       * the current query string params and replace the state so that
       * LimeLight can pick up on any forced UTM params..
       */

      const params = {
        utm_source: val,
        utm_medium: "affiliate",
      }
      if (campaign) {
        params["campaign"] = campaign
      }
      appendParamsToCurrentUrl({
        params,
      })
    }
  }
}

const useAffiliateTracking = (opts = {}) => {
  useEffect(() => {
    trackAffiliate(opts)
  }, [opts])
}

export { useAffiliateTracking }
