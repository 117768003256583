export { wrapPageElement } from "./gatsby-shared"

import { GatsbyBrowser } from "gatsby"

import "@fontsource/chivo/300.css"
import "@fontsource/chivo/400.css"
import "@fontsource/chivo/700.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

import "./src/styles/main.css"
import "./src/sites/cip/styles/theme.css"
import "./src/sites/cip-ignite/styles/theme.css"
import "./src/sites/hip/styles/theme.css"

import { RootElementWrapper } from "./src/components/RootElementWrapper"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = RootElementWrapper

export { wrapRootElement }
